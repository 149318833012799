<template>
    <admin-dashboard-layout>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col>
                        {{ page.name }}
                    </v-col>
                    <v-col class="text-right">
                        <v-btn text :to="{name: 'admin.pages.index'}">Back</v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-form ref="form">
                    <v-row>
                        <v-col>
                            <v-text-field label="Title HY" placeholder="Please enter a title in armenian" outlined :rules="nameRules" required v-model="page.name_hy" ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="article_text">
                            <label>Text HY</label>
                            <ckeditor :editor="editor" v-model="page.description_hy" required></ckeditor>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="Title EN" placeholder="Please enter a title in armenian" outlined :rules="nameRules" required v-model="page.name_en" ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="article_text">
                            <label>Text EN</label>
                            <ckeditor :editor="editor" v-model="page.description_en" required></ckeditor>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="Title RU" placeholder="Please enter a title in armenian" outlined :rules="nameRules" required v-model="page.name_ru" ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="article_text">
                            <label>Text RU</label>
                            <ckeditor :editor="editor" v-model="page.description_ru" required></ckeditor>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="Slug" placeholder="Please enter a slug (english only)" outlined
                                          v-model="page.slug"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="Created at" v-model="page.created_at" disabled ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn block color="green" @click="handleSavePage">Save Changes</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
        <v-snackbar v-model="snackbar" color="green" :timeout="timeout_snackbar">{{ text_snackbar }}</v-snackbar>
    </admin-dashboard-layout>
</template>

<script>
import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Page from "../../../models/Page";

export default {
    name: "pages-show",
    components: {AdminDashboardLayout},
    data: function () {
        return {
            page: {},
            formValid: true,
            nameRules: [
                v => !!v || 'Title is required',
                c => !!c || 'Text is required',
            ],
            editor: ClassicEditor,
            snackbar: false,
            text_snackbar: 'Saved successfully',
            timeout_snackbar: 2000,
        }
    },
    methods: {
        async handleSavePage() {
            this.formValid = this.$refs.form.validate()

            if (this.formValid) {
                const page = new Page(this.page);
                this.page = await page.save();
                await this.$router.push({name: 'admin.pages.show', params: {id: this.page.id}}).catch(()=>{});
                this.snackbar = true
            }
        }
    },
    async mounted() {
        const id = this.$route.params.id
        if (id) {
            this.page = await Page.find(id)
        }
    },
}
</script>

<style lang="scss">
    .article_text {
        .ck-editor {
            &__main{
                .ck-content {
                    height:150px!important;
                }
            }
        }
    }
</style>
